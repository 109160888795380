import React, { Fragment } from 'react';
import dateFormat from 'dateformat';
import { kebabCase } from 'lodash';
import { ScrollElement } from 'site-modules/shared/components/scroll-link/scroll-element';
import { ContentCollapse } from 'site-modules/shared/components/content-collapse/content-collapse';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';
import { Link } from 'site-modules/shared/components/link/link';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { SCROLL_OFFSET, SCROLL_DURATION } from 'site-modules/shared/constants/sub-navigation';
import { EmbeddableInfographic } from 'site-modules/shared/components/infographics/embeddable-infographic/embeddable-infographic';

import './article.scss';

const PARAGRAPHS_BEFORE_COLLAPSE = 1;

export function Article({ article }) {
  const { title = '', summary, content } = article;
  const { authorName, authorLink, date, expandArticle, anchor } = article.getAllMetadata();
  const contentParts = content.split('</p>');
  const [contentBeforeCollapse, contentAfterCollapse] = [
    contentParts.slice(0, PARAGRAPHS_BEFORE_COLLAPSE),
    contentParts.slice(PARAGRAPHS_BEFORE_COLLAPSE),
  ];
  const collapseScrollTo = `article-${title.substring(0, 6)}`;

  const articleChildren = article.children() || [];
  const infoGraphicEntry = articleChildren.filter(child => child.id === 'info-graphic')[0];

  const getContentWithInfoGraphic = (entry, contentArray) => {
    const entryInsertIndex = entry.metadata('insertAfterParagraphIndex').value();
    const index = entryInsertIndex || 1;
    const beforeContent = contentArray.slice(0, index).join('');
    const afterContent = contentArray.slice(index).join('');

    const infoGraphics = (
      <div className="p-0 mb-2">
        <EmbeddableInfographic
          imgSrc={entry.metadata('media-image').value()}
          imgAlt={entry.metadata('imageAltText').value()}
          imgUrl={entry.metadata('imageURL').value()}
          clickableUrl={entry.metadata('clickableURL').value()}
          btnText={entry.metadata('buttonTitle').value()}
        />
      </div>
    );

    return (
      <Fragment>
        <ContentFragment classes="article-content" componentToUse="div">
          {beforeContent}
        </ContentFragment>
        {infoGraphics}
        <ContentFragment classes="article-content" componentToUse="div">
          {afterContent}
        </ContentFragment>
      </Fragment>
    );
  };

  return (
    <ScrollElement id={anchor || kebabCase(title.replace(/a /gi, '').replace(/the /gi, ''))}>
      <section className="article text-gray-darker" name={collapseScrollTo}>
        <h3 className="heading-3">{title}</h3>
        <p className="heading-4 font-weight-normal text-cool-gray-40">{summary}</p>
        <p className="size-16 mb-0">
          by{' '}
          {authorLink ? (
            <Link to={authorLink} className="font-weight-bold">
              {authorName}
            </Link>
          ) : (
            <span className="font-weight-bold">{authorName}</span>
          )}
        </p>
        <p className="size-14">{dateFormat(date, 'mmmm dS, yyyy')}</p>
        <ContentFragment classes="article-content" componentToUse="div">
          {contentBeforeCollapse.join('')}
        </ContentFragment>
        <ContentCollapse
          minHeight="0px"
          btnClasses="px-0_5 py-0 size-16 text-primary-darker"
          btnContainerClasses="py-0"
          justify="justify-content-start"
          showBtnBelow
          btnTextClasses="font-weight-normal"
          classes="size-16"
          isEclipseFade={false}
          resetParams={{
            offset: SCROLL_OFFSET,
            duration: SCROLL_DURATION,
            to: collapseScrollTo,
          }}
          isOpenOnLoad={expandArticle === 'true'}
        >
          {infoGraphicEntry ? (
            getContentWithInfoGraphic(infoGraphicEntry, contentAfterCollapse)
          ) : (
            <ContentFragment classes="article-content" componentToUse="div">
              {contentAfterCollapse.join('')}
            </ContentFragment>
          )}
        </ContentCollapse>
      </section>
    </ScrollElement>
  );
}

Article.propTypes = {
  article: CmsEntities.Content,
};

Article.defaultProps = {
  article: DEFAULT_CONTENT,
};
