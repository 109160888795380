import { memo, startTransition, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const CarouselLazySlide = memo(props => {
  const { slideIndex, children, currentSlide, numberOfPreloadedSlides } = props;
  const [isRendered, setIsRendered] = useState(currentSlide === slideIndex);

  useEffect(() => {
    if (isRendered) {
      return;
    }

    if (currentSlide && slideIndex - currentSlide <= numberOfPreloadedSlides) {
      startTransition(() => {
        setIsRendered(true);
      });
    }
  }, [currentSlide, isRendered, numberOfPreloadedSlides, slideIndex]);

  return isRendered && children;
});

CarouselLazySlide.propTypes = {
  slideIndex: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
  currentSlide: PropTypes.number.isRequired,
  numberOfPreloadedSlides: PropTypes.number,
};

CarouselLazySlide.defaultProps = {
  numberOfPreloadedSlides: 2,
};
