import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SOURCES } from 'client/utils/image-helpers';
import { CmsEntities } from 'client/data/models/cms';
import { Link } from 'site-modules/shared/components/link/link';
import { Image } from 'site-modules/shared/components/image/image';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

import './article-card.scss';

export function ArticleCard({ className, content, isLazyImage }) {
  const metadata = content.getAllMetadata();

  const { 'promo-text': preview, 'article-date': articleDate, 'link-text': linkText } = metadata;

  const imageId = metadata['vehicle-image'] || metadata['media-image'];
  const title = metadata['vehicle-title'] || metadata.title;
  const linkUrl = metadata['vehicle-url'] || metadata['link-url'];

  return (
    <div className={classNames('article-card article-container text-gray-darker', className)}>
      <Link
        to={linkUrl}
        className="d-block card-link"
        data-tracking-id="inline_article_view_content"
        data-tracking-value={title}
      >
        <Image
          className="article-card-image mb-1 w-100 rounded"
          imageId={imageId}
          source={SOURCES.MEDIA}
          width={300}
          lazy={isLazyImage}
        />
        <p className="article-card-body mb-0 size-16">
          {articleDate && (
            <Fragment>
              <span className="date label text-gray-dark">{articleDate}</span>
              <br />
            </Fragment>
          )}
          <span
            className={classNames(
              'title text-gray-darkest',
              articleDate && !preview ? 'font-weight-normal' : 'font-weight-bold'
            )}
          >
            {title}
          </span>
          {!!preview && (
            <Fragment>
              <br />
              <ContentFragment componentToUse="span" classes="article-card-preview">
                {preview}
              </ContentFragment>
            </Fragment>
          )}
        </p>
        {!!linkText && (
          <div className="mt-1 text-primary-darker text-nowrap">
            {linkText} <i className="icon-arrow-right4 size-10" aria-hidden />
          </div>
        )}
      </Link>
    </div>
  );
}

ArticleCard.propTypes = {
  content: CmsEntities.Content.isRequired,
  className: PropTypes.string,
  isLazyImage: PropTypes.bool,
};

ArticleCard.defaultProps = {
  className: null,
  isLazyImage: false,
};
