import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Row from 'reactstrap/lib/Row';
import { CmsEntities } from 'client/data/models/cms';
import { ArrowLink } from 'site-modules/shared/components/arrow-link/arrow-link';
import { ContentFragment } from 'site-modules/shared/components/content-fragment/content-fragment';

import { ArticleCard } from './article-card/article-card';
import { ArticleGridCard } from './article-grid-card/article-grid-card';

import './articles-module.scss';

export const LAYOUT_TYPES = {
  default: 'default',
  grid: 'grid',
  carousel: 'carousel',
};

const renderArticleCard = ({ article, layoutType, cardSmColSize, index, isLazyImage }) => {
  switch (layoutType) {
    case LAYOUT_TYPES.grid:
      return (
        <ArticleGridCard
          key={`${article.id}-${index}`}
          content={article}
          className={`col-${cardSmColSize} col-md-6 mb-1_5`}
          isLazyImage={isLazyImage}
        />
      );
    default:
      return (
        <ArticleCard
          key={`${article.id}-${index}`}
          content={article}
          className={`col-${cardSmColSize} col-md-4 mb-1_5`}
          isLazyImage={isLazyImage}
        />
      );
  }
};

export function ArticlesModule({ content, className, dataTrackingParent, layoutType, cardSmColSize, isLazyImages }) {
  const title = content.title;
  const metadata = content.getAllMetadata();
  const promoText = metadata['promo-text'];
  const seeMoreMetadata = content.child('more-articles-link').getAllMetadata();
  const seeMoreLinkText = seeMoreMetadata['link-text'] || metadata.moreLinkText;
  const seeMoreUrl = seeMoreMetadata['link-url'] || metadata.moreLink;
  const contentEntries = content.children().filter(child => child.id !== 'more-articles-link');

  return (
    <div className={classnames('articles-module', className)} data-tracking-parent={dataTrackingParent}>
      <h2 className="heading-3 mb-1_5">{title}</h2>
      {!!promoText && (
        <ContentFragment componentToUse="p" classes="text-gray-darker mb-0_5 size-16">
          {promoText}
        </ContentFragment>
      )}
      <Row className={classnames({ 'article-row-carousel d-flex flex-nowrap': layoutType === LAYOUT_TYPES.carousel })}>
        {contentEntries.map((article, index) =>
          renderArticleCard({ article, layoutType, cardSmColSize, index, isLazyImage: isLazyImages })
        )}
      </Row>
      {!!seeMoreLinkText && (
        <ArrowLink className="mb-1_5" to={seeMoreUrl}>
          {seeMoreLinkText}
        </ArrowLink>
      )}
    </div>
  );
}

ArticlesModule.propTypes = {
  content: CmsEntities.Content.isRequired,
  className: PropTypes.string,
  dataTrackingParent: PropTypes.string,
  layoutType: PropTypes.oneOf(Object.values(LAYOUT_TYPES)),
  cardSmColSize: PropTypes.number,
  isLazyImages: PropTypes.bool,
};

ArticlesModule.defaultProps = {
  className: null,
  dataTrackingParent: undefined,
  layoutType: LAYOUT_TYPES.default,
  cardSmColSize: 12,
  isLazyImages: false,
};
