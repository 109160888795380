import { filter, flatten } from 'lodash';
import { getImageSchema } from 'client/seo/schemas/image';
import { RESEARCH_CATEGORY_EDITORIAL_PATH } from 'site-modules/shared/constants/editorial/research-article';
import { getDamPhoto } from 'client/utils/image-service';
import { parseContent, extractMetadata } from 'client/data/cms/content';
import { EditorialSeo } from 'site-modules/editorial/utils/editorial-seo';
import { getFAQSchema } from 'client/seo/schemas/faq';
import { getQAEntriesFromContent } from 'client/site-modules/shared/utils/faq';
import { getMeta } from 'site-modules/shared/utils/seo-helper';
import { getWebpageSchema } from 'client/seo/schemas/webpage';

export class ResearchCategorySeo extends EditorialSeo {
  /**
   * Override image schema retrieval
   *
   * @param {string} image
   * @see EditorialSeo.getImageWithCorrectUri
   * @return {{}} Image Schema Object
   */
  // eslint-disable-next-line class-methods-use-this
  getImageWithCorrectUri(image) {
    const { featuredTitle, featuredImage } = image;

    return getImageSchema({
      title: featuredTitle,
      uri: getDamPhoto(featuredImage),
    });
  }

  /**
   * Override hero content links retrieval
   *
   * @return {[]} Array of hero carousel image data
   */
  getHeroContentLinks() {
    const heroContent = this.articleContent.child('carousel').children();
    return heroContent.map(entry =>
      extractMetadata(['content', 'cta-url', 'title', 'cta-text', 'subtitle', 'media-image'], entry)
    );
  }
}

/**
 * Seo object build for research article page
 *
 * @param state
 * @param contentPathInState
 * @param categoryName
 * @param breadcrumbsSchema
 * @return {*}
 */
export function getSeoObject(state, contentPathInState, categoryName, breadcrumbsSchema) {
  const content = state?.cms?.content;

  // Content was not loaded because of preload timeout
  if (!content) {
    return {};
  }

  const articleContent = parseContent(content[contentPathInState]);
  const categoryContent = parseContent(content[RESEARCH_CATEGORY_EDITORIAL_PATH]);
  const meta = getMeta(articleContent);
  const { title, canonical } = meta;
  const seo = new ResearchCategorySeo(categoryContent, articleContent);

  return {
    ...meta,
    jsonld: flatten(
      filter([
        getWebpageSchema(title),
        breadcrumbsSchema || seo.getBreadcrumbs({ categoryName }),
        seo.getPhotoCarouselJsonLd(),
        seo.getArticlePageSchema('Edmunds'),
        getFAQSchema({
          entries: getQAEntriesFromContent(articleContent.child('main').child('faq')),
          canonical,
          generateLearnMoreLinks: true,
        }),
      ])
    ),
  };
}
