import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getFullImageUrl } from 'client/utils/image-helpers';
import { IMAGE_SIZES } from 'site-modules/shared/constants/editorial/editorial';
import { ClientSideRender } from 'site-modules/shared/components/client-side-render/client-side-render';
import { AspectRatioContainer } from 'site-modules/shared/components/aspect-ratio-container/aspect-ratio-container';
import { Carousel } from 'site-modules/shared/components/carousel/carousel';
import { CarouselLazySlide } from 'site-modules/shared/components/carousel/carousel-lazy-slide';

import './article-photo.scss';

function Slide({ photo, showLineUnderTitle, aspectRatio, ...restProps }) {
  return (
    <div {...restProps}>
      <AspectRatioContainer aspectRatio={aspectRatio}>
        <img
          alt={photo.title || photo.captionTranscript || ''}
          className="w-100"
          src={getFullImageUrl(photo.href, IMAGE_SIZES.lg)}
        />
      </AspectRatioContainer>
      {!!photo.captionTranscript && (
        <p className={classnames('xsmall mb-0 py-0_5', { 'title-line': showLineUnderTitle })}>
          {photo.captionTranscript}
        </p>
      )}
    </div>
  );
}

Slide.propTypes = {
  photo: PropTypes.shape({
    title: PropTypes.string,
    href: PropTypes.string,
    captionTranscript: PropTypes.string,
  }).isRequired,
  showLineUnderTitle: PropTypes.bool,
  aspectRatio: PropTypes.string,
};

Slide.defaultProps = {
  showLineUnderTitle: true,
  aspectRatio: '3:2',
};

export function ArticlePhoto({ photos, className, showLineUnderTitle, aspectRatio, isLazySlides }) {
  const [currentSlide, setCurrentSlide] = useState(0);

  const beforeChange = useCallback((from, to) => {
    setCurrentSlide(to);
  }, []);

  if (!photos.length) {
    return null;
  }

  const firstPhotoSlide = <Slide photo={photos[0]} showLineUnderTitle={showLineUnderTitle} aspectRatio={aspectRatio} />;
  return (
    <div className={classnames('article-photo', className)}>
      {// Render carousel in a case we have several photos
      // or Render single slide image in a case of only one photo
      photos.length > 1 ? (
        <ClientSideRender placeholder={firstPhotoSlide} currentSlide={currentSlide}>
          {/* Do not set infinite to true since it's affecting SEO with cloned slides */}
          <Carousel infinite={false} beforeChange={beforeChange}>
            {photos.map((photo, ind) => {
              const slideContent = (
                <Slide photo={photo} showLineUnderTitle={showLineUnderTitle} aspectRatio={aspectRatio} />
              );

              return (
                <div key={photo.href}>
                  {isLazySlides ? (
                    <CarouselLazySlide currentSlide={currentSlide} slideIndex={ind}>
                      {slideContent}
                    </CarouselLazySlide>
                  ) : (
                    slideContent
                  )}
                </div>
              );
            })}
          </Carousel>
        </ClientSideRender>
      ) : (
        firstPhotoSlide
      )}
    </div>
  );
}

ArticlePhoto.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      href: PropTypes.string,
      captionTranscript: PropTypes.string,
    })
  ),
  className: PropTypes.string,
  showLineUnderTitle: PropTypes.bool,
  aspectRatio: PropTypes.string,
  isLazySlides: PropTypes.bool,
};

ArticlePhoto.defaultProps = {
  photos: [],
  className: '',
  showLineUnderTitle: true,
  aspectRatio: undefined,
  isLazySlides: false,
};
