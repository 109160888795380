import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Col from 'reactstrap/lib/Col';
import { CmsEntities } from 'client/data/models/cms';
import { DEFAULT_CONTENT } from 'client/data/cms/content';
import { SOURCES } from 'client/utils/image-helpers';
import { Image } from 'site-modules/shared/components/image/image';
import { Link } from 'site-modules/shared/components/link/link';

import './article-grid-card.scss';

export function ArticleGridCard({ content, className, isLazyImage }) {
  const metadata = content.getAllMetadata();

  const image = metadata['vehicle-image'] || metadata['media-image'];
  const vehicleTitle = metadata['vehicle-title'] || metadata.title;
  const vehicleUrl = metadata['vehicle-url'] || metadata['link-url'];
  const vehiclePromo = metadata['vehicle-promo'] || metadata['promo-text'];

  if (!vehicleTitle || !vehicleUrl) return null;

  return (
    <div className={classnames('article-grid-card text-gray-darker', className)}>
      <Link to={vehicleUrl} className="card-link row">
        <Col xs={3}>
          <Image
            imageId={image}
            alt={vehicleTitle}
            source={SOURCES.MEDIA}
            width={175}
            className="w-100 rounded"
            lazy={isLazyImage}
          />
        </Col>
        <Col xs={9}>
          <h3 className="title size-16 font-weight-bold mb-0_25">{vehicleTitle}</h3>
          {vehiclePromo && <p className="preview d-inline-block w-100 size-16 mb-0">{vehiclePromo}</p>}
        </Col>
      </Link>
    </div>
  );
}

ArticleGridCard.propTypes = {
  content: CmsEntities.Content,
  className: PropTypes.string,
  isLazyImage: PropTypes.bool,
};

ArticleGridCard.defaultProps = {
  content: DEFAULT_CONTENT,
  className: null,
  isLazyImage: false,
};
