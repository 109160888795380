import { CAR_NEWS_CATEGORY_NAME } from 'site-modules/shared/constants/editorial/research-article';

/**
 * Returns research category name by url slug.
 * Category name is used in CMS entry ids.
 *
 * @param  {String} slug  slug from router.
 * @return {String} corrected name.
 */
export function getResearchCategoryName(slug) {
  return slug
    .replace(/^auto-/g, '')
    .replace(/^car-/g, '')
    .replace(/-car$/g, '')
    .replace(/-/g, '_');
}

export function getCategoryName(name) {
  return name === CAR_NEWS_CATEGORY_NAME ? name.replace(/-/g, '_') : getResearchCategoryName(name);
}

export function getResearchArticlePageName(articleCategory) {
  return articleCategory === 'how-to'
    ? `maintenance_${getCategoryName(articleCategory)}_article`
    : `advice_${getCategoryName(articleCategory)}_article`;
}
